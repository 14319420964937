<!-- footer start-->
<div class="container-fluid bg-dark px-5 d-none d-lg-block">
    <div class="row gx-0">
        <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div class="d-inline-flex align-items-center" style="height: 45px;">
                <small class="me-3 text-light"><i class="fa fa-map-marker-alt me-2"></i> Bogotá D.C, COLOMBIA</small>
                <small class="me-3 text-light"><i class="fa fa-phone-alt me-2"></i>+601 311 503 4703</small>
                <small class="text-light"><i class="fa fa-envelope-open me-2"></i>contacto@sitricpos.com</small>
            </div>
        </div>
        <div class="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div class="d-inline-flex align-items-center" style="height: 45px;">
                <a href="https://www.flaticon.es/autores/freepik" title="factura iconos" target="_blank">Factura iconos creados por Freepik - Flaticon</a>
            </div>
        </div>
        
        <div class="col-lg-4 text-center text-lg-end">
            <div class="d-inline-flex align-items-center" style="height: 45px;">
                <a  href="https://www.facebook.com/profile.php?id=61555123215782" target="_blank" style="margin-right: 10px;">
                    <img src="assets/images/facebook.png" alt="Facebook" class="rounded-circle" style="width: 30px; height: 30px;">
                </a> 
                <a  href="https://www.instagram.com/sistema_pos_sitric/?hl=es-la" target="_blank" style="margin-right: 10px;">
                    <img src="assets/images/instagram.png" alt="Facebook" class="rounded-circle" style="width: 30px; height: 30px;">
                </a>
                <a  href="https://www.youtube.com/@sitric-sistemadefacturacio1662" target="_blank" style="margin-right: 10px;">
                    <img src="assets/images/youtube.png" alt="Facebook" class="rounded-circle" style="width: 30px; height: 30px;">
                </a>
            </div>
        </div>
    </div>
</div>