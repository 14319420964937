import { Routes } from '@angular/router';

export const contentwebpage: Routes = [
 
  {
    path: "",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)  
  },
  {
    path: "ecommerce",
    loadChildren: () => import("../../components/e-commerce/e-commerce.module").then((m) => m.ECommerceModule),
    data: {
      breadcrumb: "Ecommerce",
    },    
  },
  
  {
    path: "inventory",
    loadChildren: () => import("../../components/inventory/inventory.module").then((m) => m.InventoryModule),
    data: {
      breadcrumb: "Inventory",
    },    
  },
  {
    path: "pricing",
    loadChildren: () => import("../../components/pricing/pricing.module").then((m) => m.PricingModule),
    data: {
      breadcrumb: "Pricing",
    },
  },
  
];