<!-- Page Header Start-->
<div class="page-main-header open">
    <div class="main-header-right row">
      <div class="main-header-left col-auto p-0 " style="height: 100%; ">
        <div class="logo-wrapper" style="height: 100%; ">
          <a href="javascript:void(0)"><img src="assets/images/endless-logo.png" alt="" style="height: 100%; "/></a>
        </div>
      </div>
  
      <div class="nav-right col">
        <ul class="nav-menus" [ngClass]="{ open: openNav }">
  
          <li class="onhover-dropdown stl-nav-01" >
            <a class="txt-dark" href="javascript:void(0)">
              <h6>Tipos de Negocio</h6>
            </a>
            <ul class="language-dropdown onhover-show-div p-20">
              <li>
                <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')">
                  <i class="fa fa-angle-double-right"></i>
                   Cafeterias</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')">
                  <i class="fa fa-angle-double-right"></i>Restaurantes</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Fruver</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Carnicerías</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Panaderías</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Gastrobar</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Droguerias</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Supermercados</a>
              </li>
             
            </ul>
          </li>
          <li class="onhover-dropdown stl-nav-01" >
            <a class="txt-dark" href="javascript:void(0)">
              <h6>Módulos</h6>
            </a>
            <ul class="language-dropdown onhover-show-div p-20">
              <li>
                <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')">
                  <i class="fa fa-angle-double-right"></i> Ventas</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')">
                  <i class="fa fa-angle-double-right"></i> Inventario</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Reportes</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Comandas y pedidos</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Control de cajas</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')">
                  <i class="fa fa-angle-double-right"></i> Empleados</a>
              </li>
            </ul>
          </li>
          <li class="stl-nav-01">
            <a class="txt-dark" href="javascript:void(0)">
              <h6 >Planes</h6>
            </a>
          </li>
          <li>
          </li>
          <li style="width: 70%;">
            <button class="btn btn-success py-2 btn-lg" routerLink="/full/authentication/login">Iniciar Sesión</button>
          </li>
  
        </ul>
        <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
          <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Header Ends -->



















